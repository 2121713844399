const coords = { x: 0, y: 0 };

const dots = document.querySelectorAll('.dots');

// const colors = [
//   '#17595e',
//   '#1c848b',
//   '#258e95',
//   '#2bafb8',
//   '#36d3de',
//   '#1dd4e0',
//   '#00eeff',
// ];

dots.forEach(function (dot, index) {
  dot.x = 0;
  dot.y = 0;
  // dot.style.backgroundColor = colors[index % colors.length];
});

window.addEventListener('mousemove', function (e) {
  coords.x = e.clientX;
  coords.y = e.clientY;
});

function animateCircles() {
  let x = coords.x;
  let y = coords.y;

  dots.forEach(function (dot, index) {
    dot.style.left = x - 12 + 'px';
    dot.style.top = y - 12 + 'px';
    dot.style.scale = (dots.length - index) / dots.length;
    dot.x = x;
    dot.y = y;

    // if (index > 5) {
    //   // todo make translate function
    //   dot.style.translate = x + 'px';
    // }

    const nextCircle = dots[index + 1] || dots[0];

    x += (nextCircle.x - x) * 0.5;
    y += (nextCircle.y - y) * 0.5;
  });

  requestAnimationFrame(animateCircles);
}

animateCircles();

const navHamburger = document.querySelector('.navbar__hamburger');
const navLinks = document.querySelector('.navbar__links');
const navLinksLi = document.querySelectorAll('.nav-links li');
const bodyEl = document.getElementsByTagName('body');

navHamburger.addEventListener('click', () => {
  navHamburger.classList.toggle('close');
  navLinks.classList.toggle('open');
  bodyEl[0].classList.toggle('no-scroll');
});

// start script for high light header and footer menu
const currentLocation = location.href;
const menuItems = document.querySelectorAll('.navbar__links .holder a');
const menuLength = menuItems.length;
for (let i = 0; i < menuLength; i++) {
  if (menuItems[i].href === currentLocation) {
    menuItems[i].classList.add('active');
  }
}
// end script for high light header and footer menu

// footer Cookie Policy
const btnAccept = document.getElementById('btnAcceptAll');
const btnDecline = document.getElementById('btnDeclineAll');
const cookies = document.getElementById('cookies');

window.addEventListener('load', () => {
  if (getCookie('tftx') == false) {
    cookies.classList.add('show');
  } else {
    cookies.classList.remove('show');
  }
});

btnAccept.addEventListener('click', (e) => {
  setCookies();
  e.preventDefault();
});
btnDecline.addEventListener('click', (e) => {
  cookies.style.display = 'none';
  e.preventDefault();
});

function setCookies() {
  cookies.style.display = 'none';
  setCookie('tftx', 'true', 1);
}
function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    // date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return false;
}

//slider start..
const galleryContainer = document.querySelector('.carousel');
const galleryItems = document.querySelectorAll('.slide-item');
const arrowIcons = document.querySelectorAll('.icon');
const content = document.querySelector('.currentSlide .content');

class Carousel {
  constructor(container, items) {
    this.carouselContainer = container;
    this.carouselArray = [...items];
  }

  updateGallery() {
    this.carouselArray.forEach((el) => {
      el.classList.remove('gallery-item-1');
      el.classList.remove('gallery-item-2');
      el.classList.remove('gallery-item-3');
      el.classList.remove('gallery-item-4');
      el.classList.remove('gallery-item-5');
      el.classList.remove('currentSlide');
      el.classList.remove('content-display');
    });

    this.carouselArray.slice(0, 5).forEach((el, i) => {
      el.classList.add(`gallery-item-${i + 1}`);
      if (i == 2) {
        el.classList.add('currentSlide');
      }
    });

    this.carouselArray.slice(5, this.carouselArray.length).forEach((el, i) => {
      el.classList.add('content-display');
    });
  }

  setCurrentState(direction) {
    if (direction == 'left') {
      this.carouselArray.unshift(this.carouselArray.pop());
      console.log(direction);
      //unshift() method adds new(last element by pop()) elements to the beginning of an array.
    } else {
      this.carouselArray.push(this.carouselArray.shift());
      console.log('right');
      // push() method adds new(removed first element by push) items to the end of an array.
    }
    this.updateGallery();
  }

  useControl() {
    arrowIcons.forEach((icon) => {
      icon.addEventListener('click', (e) => {
        e.preventDefault();
        this.setCurrentState(icon.id);
      });
    });
  }
}

const exampleCarousel = new Carousel(galleryContainer, galleryItems);
exampleCarousel.useControl();

const currentYearShow = document.getElementById('currentYearShow');

// currentYearShow;

document.addEventListener('DOMContentLoaded', () => {
  const YEAR = new Date().getFullYear();
  currentYearShow.innerHTML = '&nbsp;&nbsp;' + YEAR;
});
